import { Component, ViewContainerRef, Inject } from '@angular/core';
import { userHeaderInfo } from 'app/services/commons';
import { QSE_LAST_SITE, QSE_USER_TYPE, QSE_USER_ID, QSE_SITE_CONTRAT, QSE_LAST_AUDIT, QSE_LAST_LANGUE, UserService } from 'app/services/user.service';
import { DOCUMENT } from '@angular/common';
import { AppConfig } from 'app/app.config';
import config from 'devextreme/core/config';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TableCaption, Table,  TableToolbar } from '@ckeditor/ckeditor5-table';

declare var require: any

export const frMessages = require("devextreme/localization/messages/fr.json");
export const enMessages = require("devextreme/localization/messages/en.json");
export const itMessages = require("devextreme/localization/messages/it.json");
export const nlMessages = require("devextreme/localization/messages/nl.json");
export const esMessages = require("devextreme/localization/messages/es.json");
export const deMessages = require("devextreme/localization/messages/nl.json");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  showTimeoutDialog = false;

  
  public Editor = ClassicEditor;

  //Set toastr container ref configuration for toastr positioning on screen
  constructor(
    private idle: Idle, private keepalive: Keepalive, private userService: UserService, private router: Router,
         @Inject(DOCUMENT) private _document: HTMLDocument, public translate: TranslateService) {
    this.initMessages();

    // sets an idle timeout of 5 seconds, for testing purposes.

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Activité détectée.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.showTimeoutDialog = false;
      this.idleState = 'Temps écoulé !';
      this.timedOut = true;
      console.log(this.idleState);
      userService.logout();
      this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'Période d\inactivité détectée !'
      console.log(this.idleState);
      this.showTimeoutDialog = true;
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Vous serez déconnecté dans ' + countdown + ' secondes!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    /*
    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })
    */

  }
  stay() {
    this.showTimeoutDialog = false;
    this.reset();
  }

  logout() {
    this.showTimeoutDialog = false;
    this.userService.logout();
    this.router.navigate(['/']);
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  ngOnInit() {

    config({
      //    forceIsoDateParsing: true,
      defaultCurrency: 'EUR'
    })

    // console.log("AppComponent ngOnInit");
    // Ici, en supposant que ce composant se charge avant tous les autres, on va vérifier si
    // il n'y as pas déjà une information utilisateur
    // Celle-ci sera nulle si on n'est pas encore loggé
    // En cas de refresh de la page ou en cas de retour sur le site si la session est encore valable
    // on devrait obtenir des valeurs non nulles.

    var ut = sessionStorage.getItem(QSE_USER_TYPE);
    var id = sessionStorage.getItem(QSE_USER_ID);
    var ls = sessionStorage.getItem(QSE_LAST_SITE);
    var la = sessionStorage.getItem(QSE_LAST_AUDIT);
    var lc = sessionStorage.getItem(QSE_SITE_CONTRAT);
    var ll = sessionStorage.getItem(QSE_LAST_LANGUE);
    if (!ll || ll == "") ll = userHeaderInfo[6];
    if (!ll || ll == "") ll = "fr";
    locale(ll);
    this.translate.setDefaultLang("fr");
    this.translate.use(ll);


    userHeaderInfo[0] = ut;
    userHeaderInfo[1] = id;
    userHeaderInfo[2] = ls;
    userHeaderInfo[3] = la;
    userHeaderInfo[4] = lc;
    //  userHeaderInfo[5] // hotline true or false
    userHeaderInfo[6] = ll;


    // console.log("AppComponent ngOnInit userid=" + id + " lastsite="+ls);
  }

  initMessages() {

    loadMessages(enMessages);
    loadMessages(nlMessages);
    loadMessages(deMessages);
    loadMessages(itMessages);
    loadMessages(frMessages);

  }

  ngAfterViewInit() {
    this._document.getElementById('appFavicon').setAttribute('href', AppConfig.settings.favicon);

  }

}